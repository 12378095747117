<template>
	<div class="container">
		<el-form :model="ruleForm" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div class="content">
					<el-form-item label="退款设置：" :inline-message="true" prop="refundType">
						<el-radio v-model="ruleForm.refundType" :label="1">付款后就能申请退款</el-radio>
						<el-radio v-model="ruleForm.refundType" :label="2">发货后才可申请退款</el-radio>
						<div class="textColor">原路返回用户付款账户</div>
					</el-form-item>
					<el-form-item label="是否允许退货：" :inline-message="true" prop="isAllowReturn">
						<el-switch v-model="ruleForm.isAllowReturn" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
						<div class="textColor">默认允许退货；关闭后，将对会员隐藏退货按钮</div>
					</el-form-item>
					<el-form-item label="是否允许换货：" :inline-message="true" prop="isAllowChange">
						<el-switch v-model="ruleForm.isAllowChange" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
						<div class="textColor">默认发货后的订单允许换货；关闭后，将对会员隐藏退货按钮</div>
					</el-form-item>
					<el-form-item label="是否是否返库存：" :inline-message="true" prop="isReturnStock">
						<el-switch v-model="ruleForm.isReturnStock" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
						<div class="textColor">默认返库存，关闭后，退货将不返库存</div>
					</el-form-item>
					<el-form-item label="确认收货后是否支持退换货：" :inline-message="true" prop="isAllowReturnAfterReceived">
						<el-switch
							v-model="ruleForm.isAllowReturnAfterReceived"
							active-color="#13ce66"
							inactive-color="#ff4949"
						></el-switch>
						<div class="textColor">默认关闭，开启后订单确认之后一定时间内允许退换货</div>
					</el-form-item>
					<el-form-item label="确认收货后结算周期：" :inline-message="true" prop="commissionSettlementTime">
						<el-input
							v-model="ruleForm.commissionSettlementTime"
							style="width:80px"
							type="number"
							:min="0"
							onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this)"
						></el-input>天
						<div class="textColor">待结算周期内不发放任何佣金，结算日期才会发放佣金。周期范围1~45天。默认7天。</div>
					</el-form-item>
				</div>
			</el-card>
		</el-form>
		<div style="height:80px;"></div>
		<div class="box-card footer">
			<el-button style="width:200px;" @click="cancelSupiler()">取消</el-button>
			<el-button style="width:200px;" type="primary" @click="saveSupiler()" :loading="loading">保存</el-button>
		</div>
	</div>
</template>

<script>
import { getAfterSaleSetting, saveAfterSaleSetting } from "@/api/setting.js";
export default {
	data () {
		return {
			ruleForm: {
				refundType: 1,
				isAllowChange: false,   //是否允许换货
				isAllowReturn: false,   //是否允许退货
				isAllowReturnAfterReceived: false,   //确认收货后是否允许退换货
				isReturnStock: false,   //是否返库存
				commissionSettlementTime: '',   //佣金结算周期
			},
			loading: false,  //保存接口防止多次点击
		};
	},
	computed: {

	},
	created () {
		this.getAfterSaleSetting();
	},

	methods: {
		//获取详细数据
		async getAfterSaleSetting () {
			let res = await getAfterSaleSetting();
			this.ruleForm = {
				refundType: Number(res.data.refundType),
				isAllowChange: res.data.isAllowChange == 0 ? false : true,   //是否允许换货
				isAllowReturn: res.data.isAllowReturn == 0 ? false : true,   //是否允许退货
				isAllowReturnAfterReceived: res.data.isAllowReturnAfterReceived == 0 ? false : true,   //确认收货后是否允许退换货
				isReturnStock: res.data.isReturnStock == 0 ? false : true,   //是否返库存
				commissionSettlementTime: res.data.commissionSettlementTime,   //佣金结算周期
			}
		},
		// 保存
		saveSupiler () {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.sureSave();
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		//保存
		async sureSave () {
			this.loading = true
			let params = JSON.parse(JSON.stringify(this.ruleForm));
			params.isAllowChange = params.isAllowChange ? 1 : 0;
			params.isAllowReturn = params.isAllowReturn ? 1 : 0;
			params.isAllowReturnAfterReceived = params.isAllowReturnAfterReceived ? 1 : 0;
			params.isReturnStock = params.isReturnStock ? 1 : 0;

			let res = await saveAfterSaleSetting(params);
			if (res.success) {
				this.$message({
					type: 'success',
					message: '保存成功!'
				});
			}
			this.loading = false;
		},
		//取消保存
		cancelSupiler () {
			this.getAfterSaleSetting();
		},
	},
};
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
}
.textColor {
	color: #909399;
	font-size: 12px;
}
.footer {
	position: fixed;
	width: 100%;
	background: #fff;
	bottom: 0;
	text-align: center;
	padding: 20px 0;
	z-index: 100;
	box-shadow: 13px 1px 6px #999;
}
/deep/.el-form-item__label {
	width: 200px !important;
}
</style>
